<template>
  <section>
    <v-btn dense text color="primary" @click="$router.push({ name: 'Instructor Student Outputs', query: { class_code: $route.params.class_code, timestamp: Date.now()}})">
      <v-icon>
        mdi-chevron-left
      </v-icon>
      BACK
    </v-btn>
    <v-card class="custom-border border mx-auto">
      <section class="px-8 py-5">
        <v-row >
          <v-col cols="12" lg="3">
            <div class="poppins f13 secondary-1--text fw500">CLASS SECTION</div>
            <v-select outlined dense hide-details 
                :items="classes" 
                v-model="class_category_id" item-value="id"
                class="f14"
                return-object
              >
              <template slot="item" slot-scope="data">
                <div class="">
                  <span class="secondary--text">{{data.item.class_code}}-</span>
                  <span class="secondary-3--text">{{data.item.class_name}} </span>
                </div>
              </template>
              <template slot="selection"  slot-scope="data">
                  <div class="">
                    <span class="secondary--text">{{data.item.class_code}}-</span>
                    <span class="secondary-3--text">{{data.item.class_name}} </span>
                  </div>
              </template>
            </v-select>
          </v-col>
          
          <v-col cols="12" lg="3">
            <div class="poppins f13 secondary-1--text fw500">STUDENT</div>
            <v-select outlined dense hide-details 
                :items="students" 
                v-model="student" 
                item-value="uuid"
                class="f14"
              >
                <template slot="item" slot-scope="data">
                  <div class="">
                    {{ data.item.first_name ? $userFormat.name(data.item.first_name) : 'User' }} {{ data.item.last_name ? $userFormat.name(data.item.last_name) : 'Account' }} {{ data.item.suffix ? data.item.suffix : '' }}
                  </div>
                </template>
                <template slot="selection"  slot-scope="data">
                  <div class="">
                    {{ data.item.first_name ? $userFormat.name(data.item.first_name) : 'User' }} {{ data.item.last_name ? $userFormat.name(data.item.last_name) : 'Account' }} {{ data.item.suffix ? data.item.suffix : '' }}
                  </div>
                </template>
            </v-select>
          </v-col>
        </v-row>
      </section>
      <v-divider />
      <section class="d-flex flex-wrap align-start pa-8" v-if="!profileLoading">
        <v-avatar 
          size="80"
          class="mr-3">
          <v-img 
            alt="profile photo"
            :src="user.image ? user.image.url :require('@/assets/default-photo.png')"
            :error="user.image = null" />
        </v-avatar>
        <section class="my-2">
          <div class="f18 poppins fw600">
            {{ user.first_name ? $userFormat.name(user.first_name) : 'User' }}
            {{ user.middle_name ? $userFormat.middle_initial(user.middle_name) : '' }}
            {{ user.last_name ? $userFormat.name(user.last_name) : 'Account' }}
            {{ user.suffix ? user.suffix : '' }}
          </div>
          <!-- <div class="f14 poppins fw500 secondary-4--text">
            CLASS SECTION:  {{ user.class_category ? `${user.class_category.class_code}-${user.class_category.class_name}` : '-'}}
          </div> -->
          <section class="f12 poppins fw400 secondary-3--text mt-3">
            <v-row>
              <v-col cols='12' class="d-flex flex-row align-center fw500">
                <v-icon size="15" class="mr-1" color="primary">
                  mdi-email
                </v-icon>
                {{ user.email }}
              </v-col>
            </v-row>
          </section>
        </section>
      </section>

      <circular v-if="profileLoading"></circular>
<!--       
      <v-icon color="primary">
        mdi-message-processing
      </v-icon> -->
    </v-card>
    <v-card class="custom-border border mx-auto mt-5">
      <OutputGrading v-if="class_category_id" :class_category_id="class_category_id"/>
    </v-card>
    <v-card class="custom-border border mx-auto pa-8 mt-5">
      <OutputProgress :user_uuid="this.$route.params.uuid" :progress="progress" :loading="progressLoading" :user_id="this.user.id"/>
    </v-card>
  </section>
</template>

<script>
import OutputProgress from '../../components/teacher/student/OutputProgress.vue';
import OutputGrading from '../../components/teacher/student/OutputGrading.vue';

export default {
  components: {
    OutputProgress,
    OutputGrading
  },
  data: () => ({
    user: null,
    uuid: null,
    class_category_id: null,
    progress: [],
    profileLoading: false,
    progressLoading: false,
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    classes: [],
    students: [],
    student: null
  }),
  async created(){
    this.progressLoading = true
    this.profileLoading = true
    this.student = this.$route.params.uuid
    return await new Promise((resolve, reject) => {
      this.$api.get(`/instructor/student-output/user/${this.$route.params.uuid}`).then(res => {
        this.user = res.data.data
        this.classes = this.user.class_categories
        this.class_category_id = this.user.class_categories.find(_class => this.$route.params.class_code === _class.class_code).id
        this.profileLoading = false

        resolve(res.data.data)
      })
    }).then(async() => {
      return await new Promise((resolve, reject) => {
        this.$api.get('/instructor/student-output', { params: { class_category_id: this.class_category_id} }).then(res=>{
          this.students = res.data.data
          resolve(res.data.data)
        }).catch(err => {
          reject(err.response.data.errors)
        }).then(async() => {
          return await new Promise((resolve, reject) => {
            this.$api.get(`/instructor/student-output/course-progress/${this.$route.params.uuid}?class_category_id=${this.class_category_id}`).then((res)=>{
              this.progress = res.data.data
              this.progressLoading = false
            })
          })
        })
      })
    })
    
    
    // this.$api.get(`/instructor/student-output/user/${this.$route.params.uuid}`).then((res)=>{
    //   this.user = res.data.data
    //   this.class_category_id = this.user.class_categories.find(_class => this.$route.params.class_code === _class.class_code).id
    //   this.profileLoading = false
    //   this.$api.get(`/instructor/student-output/course-progress/${this.$route.params.uuid}?class_category_id=${this.class_category_id}`).then((res)=>{
    //       this.progress = res.data.data
    //       this.progressLoading = false
    //   })
    // })
  },
  watch: {
    class_category_id(val) {
      this.$router.replace({ params: {  ...this.$route.params, class_code: this.user.class_categories.find(_class => val.id === _class.id).class_code } });
      this.getStudentOutput()
      // this.progressLoading = true
      // this.profileLoading = true
      // return await new Promise((resolve, reject) => {
      //   this.$api.get(`/instructor/student-output/user/${this.$route.params.uuid}`).then(res => {
      //     this.user = res.data.data
      //     this.classes = this.user.class_categories
      //     this.class_category_id = this.user.class_categories.find(_class => this.$route.params.class_code === _class.class_code).id
      //     this.profileLoading = false

      //     resolve(res.data.data)
      //   })
      // }).then(async() => {
      //   return await new Promise((resolve, reject) => {
      //     this.$api.get(`/instructor/student-output/course-progress/${this.$route.params.uuid}?class_category_id=${this.class_category_id}`).then((res)=>{
      //       this.progress = res.data.data
      //       this.progressLoading = false
      //     })
      //   })
      // })
    },
    student(val) {
      this.$router.replace({ params: { ...this.$route.params, uuid: val } });
      this.getStudentOutput()
    }
  },

  methods: {
    async getStudentOutput(){
      this.profileLoading = true
      this.progressLoading = true
      return await new Promise((resolve, reject) => {
        this.$api.get(`/instructor/student-output/user/${this.student}`).then(res => {
          this.user = res.data.data
          this.classes = this.user.class_categories
          if(this.user.class_categories.findIndex(_class => this.$route.params.class_code === _class.class_code) !== -1) {
            this.class_category_id = this.user.class_categories.find(_class => this.$route.params.class_code === _class.class_code).id
          } else {
            this.class_category_id = this.user.class_categories[0].id
            this.$router.replace({ params: {  ...this.$route.params, class_code: this.user.class_categories[0].class_code } });
          }
          this.class_category_id = this.user.class_categories.find(_class => this.$route.params.class_code === _class.class_code).id
          this.profileLoading = false

          resolve(res.data.data)
        })
      }).then(async() => {
        return await new Promise((resolve, reject) => {
          this.$api.get('/instructor/student-output', { params: { class_category_id: this.class_category_id} }).then(res=>{
            this.students = res.data.data
            resolve(res.data.data)
          }).catch(err => {
            reject(err.response.data.errors)
          }).then(async() => {
            return await new Promise((resolve, reject) => {
              this.$api.get(`/instructor/student-output/course-progress/${this.student}?class_category_id=${this.class_category_id}`).then((res)=>{
                this.progress = res.data.data
                this.progressLoading = false
              })
            })
          })
        })
      })
    }
  }
}
</script>
