<template>
    <section>
        <!-- <ag-grid-vue 
            v-if="selected === 'overall_grading'"
            style="width: 100%;"
            @grid-ready="onGridReady"
            :gridOptions="{
                pagination: true,
                columnDefs: headers,
            }"
            class="ag-theme-alpine ag-theme-mycustomtheme poppins px-6"
        >
        </ag-grid-vue> -->

        <v-data-table
            v-if="selected === 'overall_grading' && !loading"
            class="poppins px-8"
            :headers="selected === 'overall_grading' ? header1 : header2"
            :items="courses">
        </v-data-table>
        <v-expansion-panels v-else-if="selected !== 'overall_grading' && !loading" flat accordion focusable class="px-8">
            <v-expansion-panel v-for="(_module, i) in modules" :key="i" >
                <v-expansion-panel-header class="poppins fw500" style="background-color: #F1F1F1">
                    <v-row dense>
                        <v-col>
                            {{ _module.title }}
                        </v-col>
                        <v-col cols="2">
                            {{ getPercent(_module.id, _module.uuid, i) }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content dense>
                    <OutputModuleGradingTable :user_id="user_id" :selected="selected" :class_category_id="class_category_id" :module_uuid="_module.uuid"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <circular v-else-if="loading"></circular>
    </section>
</template>

<script>
import OutputModuleGradingTable from './OutputModuleGradingTable.vue'
export default {
    components: {
        OutputModuleGradingTable
    },
    props: ['user_id', 'modules', 'selected', 'loading', 'class_category_id', 'courses'],
    
    data: () => ({
        header1: [
            {
                text: 'Course',
                align: 'start',
                value: 'title',
            },
            {
                text: 'Training Hrs',
                align: 'center',
                value: 'training_hrs',
            },
            {
                text: 'Final Grade',
                align: 'center',
                value: 'final_grade',
            }
        ],
        header2: [
            {
                text: 'Course',
                align: 'start',
                value: 'title',
            },
            {
                text: 'Final Grade',
                align: 'center',
                value: 'final_grade',
            }
        ],
        panel: null,
    }),
    methods: {
        getPercent(_module_id, _module_uuid, i){
            let percent = '0%'
            let _module = this.modules.find(_module => _module.id === _module_id)

            if(_module){
                let _assessment = _module.my_assessment_score.find(_item => _item.user_id === this.user_id)
                if(_assessment) {
                    let _score = _assessment.score ? _assessment.score : 0
                    let _assessment_overall_score = _module.assessment_overall_score ? _module.assessment_overall_score : 0

                    if(parseInt(_score) === 0 && parseInt(_assessment_overall_score) === 0 ) {
                        percent = '0%'
                    } else {
                        percent = `${((parseFloat(_assessment.score) / parseFloat(_module.assessment_overall_score)) * 100).toFixed(2)}%`
                    }
                }
            }
            return percent
        },
    },
}
</script>