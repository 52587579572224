<template>
    <section class="py-7">
        <v-row class="align-center justify-space-between px-7">
            <v-col lg="4" md="4" cols="9">
                <v-select
                    v-model="selected"
                    :items="items"
                    class="fw600 poppins"
                    item-text="title"
                    item-value="uuid"
                    :readonly="moduleLoading"
                    tile></v-select>
            </v-col>
            <!-- <v-col lg="3" md="3" cols="3">
                <v-btn v-if="$vuetify.breakpoint.smAndUp" dense color="success" outlined left class="f12 poppins float-right">
                    <v-icon left small>
                    mdi-export-variant
                    </v-icon>
                    Export List
                </v-btn>
                <v-btn v-else color="success" icon class="poppins float-right">
                    <v-icon>
                        mdi-export-variant
                    </v-icon>
                </v-btn>
            </v-col> -->
        </v-row>
        
        <OutputModuleGrading :user_id="user_id" :courses="courses" :modules="modules" :selected="selected" :loading="moduleLoading" :class_category_id="class_category_id"/>
    </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import OutputModuleGrading from './OutputModuleGrading.vue';
export default {
    data: () => ({
        selected: 'overall_grading',
        items: [{
            title: 'Overall Grading',
            uuid: 'overall_grading'
        }],
        modules: [],
        courses: [],
        user_id: null,
        moduleLoading: false,
        overall_grading: []
    }),
    components: { OutputModuleGrading },
    props: ['class_category_id'],
    methods: {
        ...mapActions('instructor', ['getStudentOutput', 'getStudentOutputOverallGrading', 'getStudentOutputModules']),

        getOutputGrading() {
            this.moduleLoading = true
            this.$api.get(`/instructor/student-output/overall-grading/${this.$route.params.uuid}?class_category_id=${this.class_category_id}`).then(res=>{
                this.overall_grading = res.data.data
                if(this.selected === 'overall_grading') {
                    this.getStudentOutput({ class_category_id: this.class_category_id }).then(()=>{
                        //this.courses = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                        this.courses = []
                        let _student = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                        _student.enrollments.forEach(_course => {
                            if(_course.course) {
                                if(this.overall_grading.findIndex(overall_grading_course => overall_grading_course.id === _course.course_id) !== -1) {
                                    this.items.push(_course.course)
                                    let _my_assessment_score = 0
                                    let _scores = '0%'
                                    let _overall_assessment_score = parseFloat(_course.course.overall_assessment_score ? _course.course.overall_assessment_score : 0)
                                    let _assessments = _course.course.my_assessment_score.filter(_user => _user.user_id === _student.id)

                                    if(_assessments.length > 0){
                                        _assessments.forEach(i => {
                                            _my_assessment_score += parseFloat(i.score ? i.score : 0)
                                        })
                                    }

                                    if(_my_assessment_score === 0 && _overall_assessment_score === 0) {
                                        _scores = "0%"
                                    } else {
                                        _scores = `${((_my_assessment_score / _overall_assessment_score) * 100).toFixed(2)}%`
                                    }
                                    
                                    this.courses.push(
                                        {
                                            title: _course.course.title ? _course.course.title : 'Untitled',
                                            training_hrs: `${_course.course.training_hours} hrs`,
                                            final_grade: _scores
                                        }
                                    )
                                }
                            }
                        })
                        this.moduleLoading = false
                    })
                    this.modules = []
                } else {
                    this.moduleLoading = true
                    this.modules = []
                    this.getStudentOutputModules({class_category_id: this.class_category_id, course_uuid: this.selected}).then(()=>{
                        let _user = this.state_modules.find(_item => _item.uuid == this.$route.params.uuid)
                        this.user_id = _user.id
                        if(_user.enrollments) {
                            let _course = _user.enrollments.find(_item => _item.course && (_item.course.uuid == this.selected))
                            this.modules = _course.course.modules
                        }

                        this.moduleLoading = false
                    })
                }
            })
        }
    },
    mounted(){
        this.getOutputGrading()
    },
    computed:{
        ...mapState('instructor', {
            state_modules: (state) => state.modules,
            student_output: (state) => state.student_output
        })
    },
    watch: {
        class_category_id(val){
            this.moduleLoading = true
            if(this.selected === 'overall_grading') {
                this.getStudentOutput({ class_category_id: this.class_category_id }).then(()=>{
                    //this.courses = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                    this.courses = []
                    let _student = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                    _student.enrollments.forEach(_course => {
                        if(_course.course) {
                            if(this.overall_grading.findIndex(overall_grading_course => overall_grading_course.id === _course.course_id) !== -1) {
                                this.items.push(_course.course)
                                let _my_assessment_score = 0
                                let _scores = '0%'
                                let _overall_assessment_score = parseFloat(_course.course.overall_assessment_score ? _course.course.overall_assessment_score : 0)
                                let _assessments = _course.course.my_assessment_score.filter(_user => _user.user_id === _student.id)

                                if(_assessments.length > 0){
                                    _assessments.forEach(i => {
                                        _my_assessment_score += parseFloat(i.score ? i.score : 0)
                                    })
                                }

                                if(_my_assessment_score === 0 && _overall_assessment_score === 0) {
                                    _scores = "0%"
                                } else {
                                    _scores = `${((_my_assessment_score / _overall_assessment_score) * 100).toFixed(2)}%`
                                }
                                
                                this.courses.push(
                                    {
                                        title: _course.course.title ? _course.course.title : 'Untitled',
                                        final_grade: _scores
                                    }
                                )
                            }
                        }
                    })
                    this.moduleLoading = false
                })
                this.modules = []
            } else {
                this.moduleLoading = true
                this.getStudentOutputModules({class_category_id: this.class_category_id, course_uuid: this.selected}).then(()=>{
                    let _user = this.state_modules.find(_item => _item.uuid == this.$route.params.uuid)
                    this.user_id = _user.id
                    if(_user.enrollments) {
                        let _course = _user.enrollments.find(_item => _item.course && (_item.course.uuid == this.selected))
                        this.modules = _course.course.modules
                    }

                    this.moduleLoading = false
                })
            }
        },
        selected(val) {
            if(val === 'overall_grading') {
                this.getStudentOutput({ class_category_id: this.class_category_id }).then(()=>{
                    //this.courses = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                    this.courses = []
                    let _student = this.student_output.find(_item => _item.uuid == this.$route.params.uuid)
                    _student.enrollments.forEach(_course => {
                        if(_course.course) {
                            if(this.overall_grading.findIndex(overall_grading_course => overall_grading_course.id === _course.course_id) !== -1) {
                                let _my_assessment_score = 0
                                let _scores = '0%'
                                let _overall_assessment_score = parseFloat(_course.course.overall_assessment_score ? _course.course.overall_assessment_score : 0)
                                let _assessments = _course.course.my_assessment_score.filter(_user => _user.user_id === _student.id)

                                if(_assessments.length > 0){
                                    _assessments.forEach(i => {
                                        _my_assessment_score += parseFloat(i.score ? i.score : 0)
                                    })
                                }

                                if(_my_assessment_score === 0 && _overall_assessment_score === 0) {
                                    _scores = "0%"
                                } else {
                                    _scores = `${((_my_assessment_score / _overall_assessment_score) * 100).toFixed(2)}%`
                                }
                                
                                this.courses.push(
                                    {
                                        title: _course.course.title,
                                        final_grade: _scores
                                    }
                                )
                            }   
                        }
                    })
                    this.moduleLoading = false
                })
                this.modules = []
            } else {
                this.moduleLoading = true
                this.modules = []
                this.getStudentOutputModules({class_category_id: this.class_category_id, course_uuid: this.selected}).then(()=>{
                    let _user = this.state_modules.find(_item => _item.uuid == this.$route.params.uuid)
                    this.user_id = _user.id
                    if(_user.enrollments) {
                        let _course = _user.enrollments.find(_item => _item.course && (_item.course.uuid == this.selected))
                        this.modules = _course.course.modules
                    }

                    this.moduleLoading = false
                })
            }
        },
        '$route.params.uuid'(){
            this.getOutputGrading()
        }
    }
}
</script>