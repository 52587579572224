<template>
    <section>
        <OutputTimeline :openDialog="openDialog" @close="closeDialog" :courseDetails="courseDetails" :loading="courseDetailsLoading" :user_id="user_id"/>
        <section>
        <span class="poppins f15 fw600"> Progress </span>
        <v-data-table
            class="poppins"
            :headers="headers"
            :items="progress"
            v-if="!loading"
            >
            <template v-slot:item.progress = "{ item }">
                <section class="d-flex flex-column align-center">
                    <v-progress-linear 
                        height="8"
                        :value="getProgress(item)"
                        rounded
                        color="success"
                        background-color="grey lighten-3"
                    ></v-progress-linear>
                    {{ `${getProgress(item)} %` }}
                </section>
            </template>
            <template v-slot:item.action = "{ item }">
                <v-btn text class="text-capitalize primary--text" @click="getCourseProgress(item.uuid)">
                    <v-icon class="mr-1">
                        mdi-eye-outline
                    </v-icon>
                    View Timeline
                </v-btn>
            </template>
        </v-data-table>
        <circular v-else class="my-8"/>
    </section>
    </section>
    
</template>

<script>
import OutputTimeline from './OutputTimeline.vue';
export default {
    props: ['user_uuid', 'progress', 'loading', 'user_id'],
    data: () => ({
        headers: [
            { text: "COURSE", value: "title", sortable: true, width: "33%" },
            { text: "PROGRESS", value: "progress", sortable: false, fiterable: false, align: "center", width: "33%" },
            { text: "", value: "action", sortable: false, fiterable: false, align: "center" }
        ],
        items: [
            { course: "Course1", progress: 10 }
        ],
        openDialog: false,
        courseDetails: [],
        courseDetailsLoading: false
    }),
    components: { OutputTimeline },
    mounted(){
        
    },
    methods: {
        closeDialog() {
            this.openDialog = false
        },
        getProgress(item){
            let progress = Math.round(((item.total_done_assessments + item.total_done_topics + item.total_done_sub_topics) / (item.assessments_count + item.topics_count + item.sub_topics_count)) * 100)
            return progress ? progress : 0
        },
        getCourseProgress(uuid){
            this.courseDetailsLoading = true
            this.$api.get(`/instructor/student-output/course-progress/${uuid}/student/${this.$route.params.uuid}/details`).then((res)=>{
                this.courseDetails = res.data.data
                this.courseDetailsLoading = false
            })
            this.openDialog = true
        }
    }
}
</script>