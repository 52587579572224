<template>
    <v-dialog
            persistent
            v-model="openDialog"
            hide-overlay
            transition="slide-x-reverse-transition"
            transparent
            :width="$vuetify.breakpoint.mobile ? '100vw' : '60vw'"
            right
            id="timeline_dialog"
        >
        <v-card
            id="timelinedialog" :width="$vuetify.breakpoint.mobile ? '100vw' : '60vw'" class="float-right" min-height="100vh">
            <v-container v-if="!loading">
                <v-card-title class="d-flex flex-row justify-space-between">
                    <section class="d-flex flex-row col-7">
                        <v-btn icon @click="$emit('close')">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <span class="poppins fw600" style="word-break: break-word;">{{ courseDetails.title }}</span>
                    </section>
                    <v-btn v-if="courseDetails.uploader_id === user.id" color="primary" @click="generate" class="poppins text-capitalize">
                        Generate Certificate
                    </v-btn>
                </v-card-title>
                <v-card-actions class="f13 d-flex align-center justify-end">
                    <v-btn depressed plain class="text-capitalize fw400" @click="expand_material.length === 0 ? expandAll() : closeAll()">
                        {{ expand_material.length === 0 ? 'Expand All' : 'Close All' }}
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <section ref="materialTimeline" :style="{'--bgColor': getPrimary}">
                        <v-expansion-panels class="" accordion flat v-model="expand_material" multiple>
                            <v-expansion-panel
                                v-for="(item, i) in courseDetails.modules"
                                :key="item.id"
                            >
                                <v-expansion-panel-header color="secondary-5"
                                class="mat_expansion_panel ">
                                    <template v-slot:default="{ open }">
                                        <v-container class="ma-0 pa-0 d-flex flex-column description_div">
                                            <v-row no-gutters>
                                                <v-col cols="9">
                                                <section class="description_div roboto f14 fw500 secondary-1--text">
                                                    <b>{{item.title ? item.title : 'Untitled' }}</b> 
                                                </section>
                                                </v-col>
                                                <v-col cols="3">
                                                <section class="d-flex flex-row">
                                                    <v-icon size="18" class="float-right" v-if="item.valid_until !== 'anytime' && locked(item.valid_until)">{{item.valid_until !== 'anytime' && locked(item.valid_until) ? 'mdi-lock-outline' : 'mdi-lock-open-outline'}}</v-icon>
                                                    <v-badge
                                                    v-else
                                                    bordered
                                                    :color="isModuleCompleted(item) ? 'success' : 'warning'"
                                                    size='10'
                                                    inline
                                                    class="ml-auto"
                                                    />
                                                </section>
                                                </v-col>
                                            </v-row>
                                            <section v-if="item.description" class="description_div f12 secondary-2--text mt-1" v-html="item.description ? $dompurifier(item.description) : ''"></section>
                                        </v-container>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="expansion-timeline description_div ma-0 pa-0">
                                    <div>
                                        <div
                                            v-for="(topic, j) in item.topics" 
                                            :key="topic.id"
                                        >
                                            <v-list-item dense 
                                                class="d-flex align-center">
                                                <v-icon small color="primary" v-if="topic.learning_material != null">
                                                    {{ topic.learning_material.file_type == 'youtube'
                                                        ? 'mdi-youtube'
                                                        : topic.learning_material.file_type == 'pdf'
                                                        ? 'mdi-file-pdf-box'
                                                        : topic.learning_material.file_type == 'mp4' 
                                                        ? 'mdi-file-video-outline'
                                                        : 'mdi-folder-question-outline'}}
                                                </v-icon>
                                                <v-icon small color="primary" v-else>mdi-eye-off-outline</v-icon>

                                                <v-list-item-title class="roboto secondary-1--text ml-2 f13 fw500">
                                                    {{topic.title}}
                                                </v-list-item-title>
                                                <v-badge
                                                    bordered
                                                    :class="!topic.student_done_status && 'thin-border'"
                                                    :color="topic.student_done_status ? 'success' : 'warning'"
                                                    size='10'
                                                    inline
                                                    :icon="topic.student_done_status && 'mdi-check'"
                                                >
                                                </v-badge>
                                            </v-list-item>
                                            <div
                                                v-if="topic.topic_has_assessments" 
                                                v-for="(assessments, k) in topic.topic_has_assessments">
                                                <v-list-item
                                                    dense 
                                                    link
                                                    class="d-flex align-center pl-8">
                                                    <v-icon small color="primary">
                                                        mdi-note-edit-outline
                                                    </v-icon>
                                                    <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                                                        <div class="f9 secondary-2--text">
                                                            Assessment
                                                        </div>
                                                        {{assessments.title}}
                                                    </v-list-item-title>

                                                    <v-spacer></v-spacer>
                                                    
                                                    <v-badge
                                                        bordered
                                                        :class="!assessments.assessment_score.score && 'thin-border'"
                                                        :color="assessments.assessment_score.score ? 'success' : 'warning'"
                                                        size='10'
                                                        inline
                                                        :icon="assessments.assessment_score.score && 'mdi-check'"
                                                    >
                                                    </v-badge>
                                                </v-list-item>
                                            </div>

                                            <div
                                            v-for="(sub_topic, j) in topic.sub_topics" 
                                            :key="sub_topic.id"
                                            >
                                                <v-list-item dense 
                                                    class="d-flex align-center pl-8">
                                                    <v-icon small color="primary" v-if="sub_topic.learning_material != null">
                                                        {{ sub_topic.learning_material.file_type == 'youtube'
                                                            ? 'mdi-youtube'
                                                            : sub_topic.learning_material.file_type == 'pdf'
                                                            ? 'mdi-file-pdf-box'
                                                            : sub_topic.learning_material.file_type == 'mp4' 
                                                            ? 'mdi-file-video-outline'
                                                            : 'mdi-folder-question-outline'}}
                                                    </v-icon>
                                                    <v-icon small color="primary" v-else>mdi-eye-off-outline</v-icon>

                                                    <v-list-item-title class="roboto secondary-1--text ml-2 f12 fw500">
                                                        {{sub_topic.title}}
                                                    </v-list-item-title>
                                                    <v-badge
                                                        bordered
                                                        :class="!sub_topic.student_done_status && 'thin-border'"
                                                        :color="sub_topic.student_done_status ? 'success' : 'warning'"
                                                        size='10'
                                                        inline
                                                        :icon="sub_topic.student_done_status && 'mdi-check'"
                                                    >
                                                    </v-badge>
                                                </v-list-item>
                                                <div
                                                    v-if="sub_topic.sub_topic_has_assessments" 
                                                    v-for="(assessments, k) in sub_topic.sub_topic_has_assessments"
                                                    :key="assessments.id">
                                                    <v-list-item
                                                        dense 
                                                        link
                                                        class="d-flex align-center pl-13">
                                                        <v-icon small color="primary">
                                                            mdi-note-edit-outline
                                                        </v-icon>
                                                        <v-list-item-title  class="roboto secondary-1--text ml-2 f12 fw500">
                                                            <div class="f9 secondary-2--text">
                                                                Assessment
                                                            </div>
                                                            {{assessments.title}}
                                                        </v-list-item-title>

                                                        <v-spacer></v-spacer>

                                                        <v-badge
                                                            bordered
                                                            :class="!assessments.assessment_score.score && 'thin-border'"
                                                            :color="assessments.assessment_score.score ? 'success' : 'warning'"
                                                            size='10'
                                                            inline
                                                            :icon="assessments.assessment_score.score && 'mdi-check'"
                                                        >
                                                        </v-badge>
                                                    </v-list-item>
                                                </div>
                                            </div>
                                        </div>  
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </section>
                </v-card-text>
            </v-container>
            <circular v-else class="my-15"/>
            <CertificatePreview :dialog="dialog" @close="dialog = false" :loading="certificateLoading" :error="error" :url="url"/>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.v-expansion-panel-header.mat_expansion_panel {
  align-items: start !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.description_div {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.expansion-timeline .v-expansion-panel-content__wrap {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.topic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.subtopic.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}
.assessments.timeline-container.active::after {
  background-color: var(--bgColor);
  border:  2px solid var(--bgColor);
}

.v-badge.thin-border .v-badge__badge::after {
  border-color: #BDBDBD !important;
  border-width: 1px !important;
  margin: 1px !important;
}

</style>

<script>
import { mapState } from 'vuex'
import CertificatePreview from './CertificatePreview.vue'

export default {
    props: ["openDialog", "courseDetails", "loading", "user_id"],
    components: {
        CertificatePreview
    },
    data: () => ({
        expand_material: [],
        dialog: false,
        certificateLoading: false,
        error: null,
        url: null
    }),
    mounted(){
        this.expandAll()
    },
    methods: {
        generate() {
            this.error = null
            this.certificateLoading = true
            this.dialog = true
            this.$api.post('/instructor/generate/certificate', { user_id: this.user_id, course_id: this.courseDetails.id}).then(res=> {
                this.certificateLoading = false
                this.url = res.data.data
                // setTimeout(() => {
                //     this.dialog = false
                // }, 2000)
            }).catch(e => {
                this.error = e
                this.certificateLoading = false
                this.url = e.response.data.data
            })
        },
        expandAll(){
            let i = 0
            while ( i < this.courseDetails.modules.length) {
                this.expand_material.push(i);
                i++;
            }
        },

        closeAll(){
            this.expand_material = []
        },

        isModuleCompleted(_module){
            for(let topic of _module.topics){
                if(!topic.student_done_status) {
                return false
                } else {
                for(let topic_assessment of topic.topic_has_assessments) {
                    if(!topic_assessment.assessment_score.score) {
                    return false
                    }
                }

                for(let sub_topic of topic.sub_topics) {
                    if(!sub_topic.student_done_status) {
                    return false
                    } else {
                    for(let sub_topic_assessment of sub_topic.sub_topic_has_assessments) {
                        if(!sub_topic_assessment.assessment_score.score) {
                        return false
                        }
                    }
                    }
                }
                }
            } 
            if(_module.topics.length === 0) return false
            return true
            },
    },

    computed: {
        ...mapState({
            customization: (state) => state.customization,
            user: (state) => state.user
        }),
        getPrimary(){
            return this.customization.hasOwnProperty('primary_buttons_color') ? this.customization.primary_buttons_color : this.$vuetify.theme.currentTheme.primary
        },
    }
}
</script>

<style>
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
    margin: 0px !important;
}
.expansion-timeline .v-expansion-panel-content__wrap {
  padding-bottom: 0 !important;
  
}
</style>

<style scoped>
.v-dialog__content {
    justify-content: flex-end !important;
}

</style>